import styled from "styled-components";
import Fundo from '../img/img_OndaAmarelas.png';

export const DivPromocoes = styled.div`
margin: auto;
width: 85.9%;
margin-top: 2vh;
font-family: Verdana, Geneva, Tahoma, sans-serif;
border: solid 3px rgb(205, 205, 207);

.Promo {
    margin-top: 1.0vw;
    display: flex;
    align-items: center;
    color: #696969;

    .Sale{
        img{
            width: 25vw;
            margin-left: 3vw;
        }
    }

    .prom-02{
        font-size: 3.0vw;
        font-weight: bold; 
        margin-left: 10.0vw;

        li{
            padding: 1vw;
            margin-top: 2.0vw;
            font-size: 3.0vw;
            font-weight: normal; 
        }
    }
 }
    .fundo-rodape{
        background-image: url(${Fundo});
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        text-align: right;

        img{
            width: 20%;
        }
    }

@media screen and (max-width: 750px) {
    
    margin: 0px;
    padding: 0px;
    width: 98.50%;
    
    .Promo {
    
        .Sale{
    
            img{
                width: 25vw;
                margin-left: 5vw;
                margin-top: 2vw;
            }
        }
    
        .prom-02{
            font-size: 3.0vw;
            font-weight: bold; 
            margin-left: 15vw;
        }
    }
} 

`;