import styled from "styled-components";
import fundo from '../img/img_FundoAmarelo.jpg'

export const Body = styled.div`

    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;;

    width: 99.7vw;
    height: 99.7vh;
    position: absolute;
    background-repeat: no-repeat;
    background-color: #F0E68C;
    font-family: Cambria, Verdana, Geneva, Tahoma, sans-serif;
    color: #363636;
    font-size: 1.8vw;

    @media screen and (max-width: 760px) {
        height: auto;
        background-image: url(${fundo});
    }
        
`

export const BotaoLeft = styled.div`
    margin-top: 3vh;
    margin-left: 3vh;
    border: none;
    .left{ width: 5vw }
    /* border: solid 1px sandybrown; */
    img{
        :hover{
            border-radius: 50% ;
            background-color: #0d6efd;
            color: #fff;
            }
    }

    @media screen and (max-width: 760px) {
        display: none;
    }
`
export const DivDigital = styled.div`

    margin: auto;
    text-align: center;
    width: 35vw;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -47%);

    .CalcDigital {

        border-radius: 10px;
        background-image: url(${fundo});
        background-size: 100%;

            .topo {
                padding-top: 1.0vh;
                font-size: 2.5vw
            }

            hr { border: solid 2px #B8860B };

            select{
                margin-bottom: 0.8vh;
                width: 20vw;
                font-family: Cambria, Verdana, Geneva, Tahoma, sans-serif;
                font-size: 1.25vw;
                color: #4F4F4F;
            }
        
            input {
                margin: auto;
                width: 15vw;
                border: 1px, solid, #ccc;
                border-radius: 5px;
                font-size: 1.25vw;
                text-align: center;
            }

            button {
                background-color: #fff;
                color: #0d6efd;
                width: 15vw;
                margin-top: 1.5vw;
                border: 2px solid #0d6efd;
                border-radius: 5px;
                cursor: pointer;
                font-size: 1.5vw; 
                :hover{
                    background-color: #0d6efd;
                    color: #fff;
                    }
            }

            .resultado{
                margin: 1vh;
                color: green;
                padding-bottom: 2vh;
            }
    }

    @media screen and (max-width: 760px) {

        width: 100vw;
        height: auto ;
        position: none;
        left: 0;
        top: 13vh;
        transform: none;

        .CalcDigital {
            width: 100vw;
            background-image: none;
            border-radius: 0px;
            font-size: 5vw; 

                .topo {
                font-size: 7vw ;
                margin-bottom: 1.5vh ;
                }

                hr{display: none }

                select{
                    width: 85vw;
                    padding: 2.00vw;
                    font-size: 4.50vw;
                    margin-bottom: 1.0vh;
                }
            
                input {
                    width: 55vw;
                    font-size: 4.00vw;
                    margin-bottom: 2.5vh ;
                }

                button {
                    width: 45vw;
                    font-size: 4.5vw; 
                }

                .resultado{
                padding-bottom: 0.5vh;
                color: white;
            }
        }
    }

`;
