import React from "react";
import { Link } from 'react-router-dom'
import { BotaoLeft } from "../style";
import { SiteTopo } from "../../topo";
import SetaEsquerda from '../../img/Seta_Esquerda.png';

import video01 from '../../arquivoVideos/video01Konica.mp4';
import video02 from '../../arquivoVideos/video02Versa.mp4';
import video03 from '../../arquivoVideos/video03Zebra.mp4';
import video04 from '../../arquivoVideos/video04Batida.mp4';
import video05 from '../../arquivoVideos/video05CutLabel.mp4';
import { TopoSeta, Videos } from './style';

export const VideosDigital = () => {

	return (

		<>

			<TopoSeta>
				<BotaoLeft>
					<Link to='/'>
						<img className="left" src={SetaEsquerda} alt="seta voltar pagina inicial" />
					</Link>
				</BotaoLeft>
				<SiteTopo />
			</TopoSeta>

			<Videos>

				<div className='videosemlinha'>
					<label>Impressora Digital HD</label>
					<video className='video' controls >
						<source src={video01} type="video/mp4" />
					</video>
				</div>
				<div className='videosemlinha'>
					<label>Impressora Digital de Mesa</label>
					<video className='video' controls >
						<source src={video02} type="video/mp4" />
					</video>
				</div>
				<div className='videosemlinha'>
					<label>Imp. Digital de Thermotransferência</label>
					<video className='video' controls >
						<source src={video03} type="video/mp4" />
					</video>
				</div>
			</Videos>

			<Videos>

				<div className='videosemlinha'>
					<label>Máquina de Corte Tipo Batida</label>
					<video className='video' controls >
						<source src={video04} type="video/mp4" />
					</video>
				</div>
				<div className='videosemlinha'>
					<label>Máquina de Corte Tipo Plotter</label>
					<video className='video' controls >
						<source src={video05} type="video/mp4" />
					</video>
				</div>
			</Videos>
		</>
	)
}


