import styled from "styled-components";

export const DivCarrosel = styled.div `
    margin: auto;
    margin-top: 2vh;
    align-content: center;
    text-align: center;

    img {
        width: 50vw;
        border: solid 1px #D3D3D3;
        border-radius: 6px;
        };

@media screen and (max-width: 750px) {
    
    margin-top: 0.65vh;

    img {
        width: 95vw;
        border: none;
        border-radius: 0;
        };
    }
`;

