import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {SiteEPC} from './pages/epcsite'
import {CalcDigital} from './pages/epcsite/digital'
import { VideosDigital } from '../src/pages/epcsite/digital/videos';


function App() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route exact  path='/' element={<SiteEPC />} />
                <Route exact  path='/digital' element={<CalcDigital />} />
                <Route exact  path='/digital/videos' element={<VideosDigital />} />

            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
