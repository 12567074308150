import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { Body, DivDigital, BotaoLeft } from './style'
import SetaEsquerda from '../img/Seta_Esquerda.png';
import { SiteTopo } from "../topo";
import { TopoSeta } from "./videos/style";

export const CalcDigital = () => {

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    })

    const [radio, setRadio] = useState('1')
    const [etqquantidade, setEtqquantidade] = useState('')
    const [etqlargura, setEtqlargura] = useState('')
    const [etqaltura, setEtqaltura] = useState('')
    const [total, setTotal] = useState('')
    const [retornoTotal, setRetornoTotal] = useState('')

    var MateriaPrima = '';
    const Couche = 3;
    const Bopp_20gr = 5;
    const Bopp_30gr = 5.5;
    const Bopp_B_30gr = 10;
    const Bopp_Prata = 12;
    const Bopp_Transp = 7;
    const Vinil = 15;
    const Couche_Esp_Vergara = 15;

    (radio === '1') ? MateriaPrima = Couche :
        (radio === '2') ? MateriaPrima = Bopp_20gr :
            (radio === '3') ? MateriaPrima = Bopp_30gr :
                (radio === '4') ? MateriaPrima = Bopp_B_30gr :
                    (radio === '5') ? MateriaPrima = Couche_Esp_Vergara :
                        (radio === '6') ? MateriaPrima = Bopp_Transp :
                            (radio === '7') ? MateriaPrima = Bopp_Prata :
                                (radio === '8') ? MateriaPrima = Vinil :
                                    <></>

    useEffect(() => {
        setRetornoTotal(total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
    }, [total])

    //useEffect(() => {
    //    (etqquantidade !== '' && etqquantidade > 100) ||
    //    (etqaltura !== '' && etqaltura > 25) ||
    //    (etqlargura !== '' && etqlargura > 25) ?
    //    calcular() : <></>
    //    
    //}, [etqquantidade, etqaltura, etqlargura] )


    const calcular = () => {


        (etqquantidade === '' || etqquantidade < 100) ? alert('Preencha a quantidade de etiquetas, com um minimo de 100')
            : (etqlargura === '' || etqlargura < 25 || etqlargura > 300) ? alert('Preencha a largura da etiqueta em mm com minimo de 25 e maximo de 300')
                : (etqaltura === '' || etqaltura < 10 || etqaltura > 295) ? alert('Preencha a altura da etiqueta em mm com minimo de 10 e maximo de 295') :
                    <>
                    </>

        const etiqQuantNumber = Number(etqquantidade)
        const etiqAlturaNumber = Number(etqaltura)
        const etiqLarguraNumber = Number(etqlargura)
        const radioNumber = Number(radio)

        let tamanhoetiq = (etiqAlturaNumber * etiqLarguraNumber) / 1000

        console.log(tamanhoetiq);

        let qtdCarreirasRolo = Math.trunc(300 / (etiqLarguraNumber + 6)) // calculo de qtas carreiras cabem no papel
        let qtdLinearMP = ((etiqAlturaNumber + 5) * etiqQuantNumber / 1000) / qtdCarreirasRolo // linear p/ calculo click
        const Custo_Click = qtdLinearMP * 2.30 // Calculo Click

        let LarguraEtiq = 330 / Math.trunc(qtdCarreirasRolo); // qtas carreiras cabem na largura do material
        const MetroQ = ((LarguraEtiq * (etiqAlturaNumber + 5)) / 1000000) * etiqQuantNumber;
        const calcMP = Number(MetroQ * MateriaPrima);

        var ValorCorte = '';
        tamanhoetiq < 2.50 ? ValorCorte = Number(0.08 * etiqQuantNumber) :
            tamanhoetiq < 2.25 ? ValorCorte = Number(0.10 * etiqQuantNumber) :
                tamanhoetiq < 2.00 ? ValorCorte = Number(0.12 * etiqQuantNumber) :
                    tamanhoetiq < 1.50 ? ValorCorte = Number(0.14 * etiqQuantNumber) :
                        tamanhoetiq < 1.00 ? ValorCorte = Number(0.18 * etiqQuantNumber) :
                            tamanhoetiq < 0.625 ? ValorCorte = Number(0.20 * etiqQuantNumber) : <></>

        ValorCorte = Number(0.025 * etiqQuantNumber);  // cada mil etiq cortada cobra 20.00
        const Ajuste_Máquina = 2.30 * MateriaPrima // Perca de material

        const soma1 = calcMP + Custo_Click

        let Margem0 = soma1 * 1.50 // Margem de Lucro até 1 M²
        let Margem1 = soma1 * 1.25 // Margem de Lucro até 2 M²
        let Margem2 = soma1 * 1.10 // Margem de Lucro até 4 M²
        let Margem3 = soma1 * 1.00 // Margem de Lucro até 7 M²
        let Margem4 = soma1 * 0.80 // Margem de Lucro até 14 M²
        let Margem5 = soma1 * 0.65 // Margem de Lucro acima de 14 M²

        const soma2 = MetroQ <= 2 ? Margem0 : MetroQ <= 2 ? Margem1 : MetroQ <= 4 ? Margem2 : MetroQ <= 7 ? Margem3 : MetroQ <= 14 ? Margem4 : MetroQ > 14 ? Margem5 : <></>

        var soma = ''

        const soma3 = ValorCorte + Ajuste_Máquina

        const presoma = soma1 + soma2 + soma3

        let adicionalMinimo = (100 - presoma) + ValorCorte // Adicional para dar um valor minimo de 100.00

        presoma > 100 ? soma = presoma : soma = presoma + adicionalMinimo

        etqquantidade ? setStatus({ type: 'success' }) : <></>
        etqlargura ? setStatus({ type: 'success' }) : <></>
        etqquantidade ? setStatus({ type: 'success' }) : <></>
        radioNumber ? setTotal(soma) : <></>
    };

    return (
        <>

            <Body>

			<TopoSeta>
				<BotaoLeft>
					<Link to='/'>
						<img className="left" src={SetaEsquerda} alt="seta voltar pagina inicial" />
					</Link>
				</BotaoLeft>
				<SiteTopo />
			</TopoSeta>

                <DivDigital>
                    <div className="CalcDigital">

                        <div className="topo">
                            <div>Impressão Digital<br />
                                Orçamento de Etiquetas</div>
                        </div>

                        <hr />

                        <form>
                            <div onChange={(e) => { setRadio(e.target.value) }}>
                                <label>Selecione o Material</label>< br />
                                <select name='radio' value={radio} onChange={e => setRadio(e.target.value)}>
                                    <option value="1" >COUCHE</option>
                                    <option value="2">BOPP FOSCO/BRILHO (Cola Acilica 20gr)</option>
                                    <option value="3">BOPP FOSCO (Cola Borracha 30gr)</option>
                                    <option value="4">BOPP BRILHO (Cola Borracha 30gr)</option>
                                    <option value="6">BOPP TRANSPARENTE</option>
                                    <option value="7">BOPP PRATA</option>
                                    <option value="8">VINIL BRANCO</option>
                                    <option value="5">COUCHE VERGARA (Especial P/ Vinho e Chocolate)</option>
                                </select>
                            </div>
                        </form>

                        <div>
                            <label>Quantidade de Etiquetas</label> <br />
                            <input type="number" name='etqquantidade' onChange={(e) => setEtqquantidade(e.target.value)}
                                placeholder="Minimo 100 etiqs." required />
                        </div>
                        <div>
                            <label>Largura da Etiqueta</label> <br />
                            <input type="number" name='etqlargura' onChange={(e) => setEtqlargura(e.target.value)}
                                placeholder="Mín. 25mm máx. 300mm" required />
                        </div>
                        <div>
                            <label>Altura da Etiqueta</label> <br />
                            <input type="number" name='etqaltura' onChange={(e) => setEtqaltura(e.target.value)}
                                placeholder="Mín. 10mm máx. 295mm" required />
                        </div>
                        <div>
                            <button onClick={calcular}>Calcular</button>
                        </div>

                        {(status.type === 'success') ?
                            <div className="resultado">
                                O total de {etqquantidade} etiquetas é {retornoTotal} </div>
                            : <p className="resultado">Preencha todos os campos !!!</p>
                        }
                    </div>
                </ DivDigital>
            </ Body>
        </>
    )
}
export default CalcDigital;
