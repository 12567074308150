import React from "react";
import { DivRibbons } from './style';

import Cera from '../img/img_Ribbon_Cera.png';
import Misto from '../img/img_Ribbon_Resina.png';
import Resina from '../img/img_Ribbon_Prateleira.png';
import Fundo from '../img/img_logoEPC_medio.png';

export const Ribbons = () => {

    return (
        <DivRibbons>

            <div className="linha1">

                <div className="titulos">

                    RIBBON CERA
                    <div className="ribbon-texto">
                        De valor mais acessivel, o seu ponto forte é que pode ser usado na maioria dos substratos (papeis e Filmes).
                        Seu ponto fraco é que a etiqueta não pode sofrer muito atrito, podendo borrar e ficar ilegível.
                        Composto com 100% cera.
                    </div>
                </div>
                <div className="imagens">
                    <img src={Cera} alt='ribbon cera' />
                </div>
            </div>
 
            <div className="linha1">
                <div className="titulos">
                    RIBBON CERA-RESINA
                    <div className="ribbon-texto">
                        Chamado também de "Misto", é composto por uma mistura de Cera e Resina. Seu valor é mais
                        agregado, a sua resistência ao atrito é um ponto extremamente forte, podendo sofrer atrito e
                        suportar muitas intempéries de transporte.
                    </div>
                </div>
                <div className="imagens">
                    <img src={Misto} alt='ribbon cera-resina' />
                </div>
            </div>

            <div className="linha1">
                <div className="titulos">
                    RIBBON RESINA
                    <div className="ribbon-texto">
                        Geralmente usado em filmes (BOPP e OPP, etc), a sua resistência ao atrito e produtos
                        quimicos é excelente, entretanto o custo é elevado. Composto com 100% resina.
                    </div>

                </div>
                <div className="imagens">
                    <img src={Resina} alt='ribbon resina' />
                </div>
            </div>
            <div className="fundo-ribbon">
                    <img src={Fundo} alt='fundo rodapé' />
            </div>


        </DivRibbons>
    )
};