import {SiteTopo} from "./topo";
import {Carrosel} from './carrosel/index';
import {SobreEPC} from './sobre';
import {Etiquetas} from './etiquetas';
import {Ribbons} from './ribbons';
import {Impressoras} from './impressoras';
import {Promocoes} from './promocao';
import {FaleConosco} from './faleconosco'

export const SiteEPC = () => {

    return (
        <>
            <SiteTopo />
            <Carrosel />
            <SobreEPC />
            <Etiquetas />
            <Ribbons />
            <Impressoras />
            <Promocoes />
            <FaleConosco />
        </>
    )
}
