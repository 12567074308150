import styled from "styled-components";


export const TopoSeta = styled.div `

    display: flex ;
    margin-bottom: 1vh ;
`

export const Videos = styled.div`

    margin: 0 ;
    top: 20px ;
    width: auto ;
    display: flex ;
    align-items: center;
    justify-content: space-between;
    /*align-content: space-around;*/
    text-align: center ;
    background-color: #DCDCDC;
    font-family: Cambria, Verdana, Geneva, Tahoma, sans-serif;
    color: red;
    font-size: 1.8vw;

    .videosemlinha{
        width: 31.5vw ;
        border: white 2px solid ;
        border-radius: 5px; 
        margin: 2vh ;

        .video {
            width: 25vw;
            height: 20vw;
        }
    }

    @media screen and (max-width: 760px) {

        margin: auto ;
        display: block ;
        width: auto ;
        border-radius: 5px; 
        font-size: 3.8vw;
        color: white ;


        .videosemlinha{
            margin: auto ;
            display: block ;
            border: black 1px solid ;
            width: 70vw;
            margin-bottom: 2vh ;
            background-color: #A9A9A9;


            .video {
            width: 50vw;
            height: 40vh;
        }


        }

    }
`
