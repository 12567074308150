import styled from "styled-components";
import fundo from '../img/img_OndaAmarelas.png';

export const DivRibbons = styled.div`
margin: auto;
width: 85.9%;
margin-top: 2vh;
font-family: Verdana, Geneva, Tahoma, sans-serif;
font-size: 3.2vw;
background-color: rgb(236, 229, 229);

    .linha1 {
    padding: 3px;
    display: flex;
    align-items: center;
    border: solid 3px rgb(205, 205, 207);
}
    .imagens {
    padding: 10px;
    width: 30%;
    display: flex;
    justify-content: space-around;

        img{
            width: 100% ;
        }
}
    .titulos {
        padding: 10px;
        width: 70%;
        margin-left: 10px;
        text-align: left;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-weight: bold;
        font-size: 2.5vw;
        color: #696969 ;
}
.ribbon-texto {
    margin-right: 10px;
    margin-top: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: normal;
    font-size: 1.3vw;
}
.fundo-ribbon{
    background-image: url(${fundo});
    background-repeat: no-repeat;
    // background-size: cover;
    text-align: right;
    padding: 5px;
    border: solid 3px rgb(205, 205, 207);
    img{
        width: 20%;
    }
}

@media screen and (max-width: 750px) {
    margin:0;
    padding:0;
    width: 100%;

    .imagens {
        padding: 1px;
        width: 40%;
            img{
                width: 100% ;
            }
    }
    
    .titulos {
        padding: 1px;
        width: 60%;
        margin-left: 5px;
        text-align: left;
        font-size: 2.8vw;
    }

    .ribbon-texto {
        margin-top: 5px;
        font-size: 2.3vw;
    }

`;
