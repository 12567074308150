import styled from "styled-components";
import fundo from '../img/img_FundoAmarelo2.jpg';

export const Topo = styled.div `
margin: auto;
width: 84.8vw;
background-image: url(${fundo});
display: flex;
justify-content: space-around;
align-items: center;
font-family: Arial, Georgia, Times, serif;

        .Icones {
            text-align: center;

            img { width: 4vw }

            .logo{ width: 8vw;
                   padding: 0.5vw }

            span p { display: none };
            span:hover img{ display: none };
            span:hover p { display: block;
                           font-size: 2.0vw };

            .Dolar{ 
                                    
                .Dlr1 {
                        width: 8vw;
                        padding: 3px;
                        background-color: rgb(247, 180, 55);
                        color: rgb(247, 243, 243);
                        font-size: 1.2vw }
                .Dlr2{
                        padding: 3px;
                        color: rgb(34, 33, 33);
                        background-color: rgba(175, 167, 167, 0.801);
                        font-size: 1.2vw } 
                }                
        }

        @media screen and (max-width: 750px) {
                
        margin: 0px;
        padding: 0px;
        width: 100%;
        justify-content: space-between;
                .Icones{
                        width: 30%;
                }

                .telefone, .dolar, .email, .instagram {
                        display: none
                }

                .Icones .logo {
                        width: 20vw;
                        padding: 2.5vw;
                }

                .Icones img {
                        width: 10vw;
                }
}
`;
