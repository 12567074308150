import React from "react";
import { DivFale } from "./style";
import Logo from '../img/img_logoEPC_medio.png'
import mapaEpc from '../img/mapa_epc.png'



export const FaleConosco = () => {

    return (

        <DivFale>

            <div className="blocos">

                <div className="bloco-01">
                    <h1>FALE CONOSCO
                        <div className="traco"></div>
                    </h1>

                    <div>
                        <img src={Logo} alt="imagem da logo etiquetapontocom" />
                    </div>

                    <div className="mapa">
                        <a href='https://goo.gl/maps/qjdzdcYx2pwX8eSj6' target="_blank" rel="noopener noreferrer">
                            <img src={mapaEpc} alt='Localização EPC' />
                        </a>
                    </div>
                    
                </div>

                <div className="bloco-02">
                    <h2 > ENDEREÇO </h2>
                    <h3 >Rua Marieta da Silva, 178 <br />Vila Guilherme<br />
                        CEP 02066-030 - São Paulo - SP<br />Brasil</h3>

                    <h2> E-MAIL </h2>
                    <h3>etiqueta@etiquetapontocom.net </h3>

                    <h2> TELEFONE </h2>
                    <h3>(11) - 2227-7810 </h3>

                    <h2> WHATsAPP </h2>
                    <h3>(11) 9.8174-3283</h3>

                </div>

                <div className="bloco-03">
                    <div className="fundo-lateral"></div>
                </div>

            </div>

        </DivFale>
    )
}