import React, { useState } from "react";
import { useEffect } from "react";
import { Topo } from '../topo/style';
import telefone from '../img/telefone.png';
import logoGrande from '../img/img_logoEPC_grande.png';
import whatsapp from '../img/WhatsApp.png';
import email from '../img/Email.png';
import instagran from '../img/Instagram.png';


export const SiteTopo = () => {


    const [dolar, setDolar] = useState();
    const [data, setData] = useState();
    const [pendente, setPendente] = useState(true);

    setTimeout(function () {
        let url = "https://economia.awesomeapi.com.br/all/USD-BRL";
        fetch(url)
            .then((resposta) => { return resposta.json() })
            .then((dados) => {
                let dolar = dados.USD.bid
                let dataSite = dados.USD.create_date
                var dataConvertida = new Date(dataSite);
                let data = (dataConvertida.toLocaleDateString());
                setDolar(dolar)
                setData(data)
                setPendente(false)
            });
    }, 100);

    useEffect(() => {
        setInterval(function () {
            let url = "https://economia.awesomeapi.com.br/all/USD-BRL";
            fetch(url)
                .then((resposta) => { return resposta.json() })
                .then((dados) => {
                    let dolar = dados.USD.bid
                    let dataSite = dados.USD.create_date
                    var dataConvertida = new Date(dataSite);
                    let data = (dataConvertida.toLocaleDateString());
                    setDolar(dolar)
                    setData(data)
                    setPendente(false)
                });
        }, 60000);
    }, [])

        return (

            <Topo>

                <div className="Icones logo">
                    <img className="logo" src={logoGrande} alt="logo" />
                </div>

                <div className="Icones telefone">
                    <span>
                        <p>(11) 2227-7810 </p>
                        <img src={telefone} alt="telefone" />
                    </span>
                </div>

                <div className="Icones whatsapp">
                    <a href="http://api.whatsapp.com/send?1=pt_BR&amp;phone=5511981960334" target="blank">
                        <img src={whatsapp} alt="whatsapp" />
                    </a>
                </div>

                <div className="Icones email">
                    <a href="mailto:etiqueta@etiquetapontocom.net">
                        <img src={email} alt="email" />
                    </a>
                </div>

                <div className="Icones instagram">
                    <a href="https://www.instagram.com/etiquetapontocom" >
                        <img src={instagran} alt="instagran" className="insta" />
                    </a>
                </div>

                <div className="Icones dolar">
                    {pendente === true ? (<div>Loading...</div>) :
                        (
                            <div className="Dolar">
                                <div className="Dlr1">US$</div>
                                <div className="Dlr2">{dolar} </div>
                                <div className="Dlr1">{data} </div>
                            </div>
                        )}
                </div>
            </Topo>
        )
    }