import styled from "styled-components";
import Teclado from '../img/img_FundoTeclado.jpg'
import FundoLateral from '../img/img_FundoAmarelo.jpg'

export const DivFale = styled.div`
display: flex;
margin: auto;
width: 85.9%;
margin-top: 2vh;
background-image: url(${Teclado});
background-repeat: no-repeat;
font-family: Verdana, Geneva, Tahoma, sans-serif;

h1 { font-size: 2.0vw;};
h2 { font-size: 1.8vw;
      color: #696969 };
h3{ font-size: 1.4vw;
    color: #4F4F4F;
    font-weight: normal }

    .blocos{
        width: 100%;
        display: flex;
        margin: auto;
        align-content: space-between;

        .bloco-01{
            width: 50%;
            margin-top: 2vh;
            text-align: center;
            align-items: center;

                .traco{
                    margin: auto;
                    width: 20vw;
                    height: 0.6vh;
                    background-color: red;
                }

                img {
                        width: 25vw;
                        margin-top: 5vh;
                    }

                .mapa{
                    margin: auto;
                    width: 20vw;

                    img {
                        width: 15vw;
                        border: solid 1px rgb(205, 205, 207);
                        border-radius: 10px;
                        margin-top: 5vh;
                    }
                }
            }
        }

        .bloco-02{
            margin-top: 2vh;
            width: 50%;
            margin-right: 10vw;
            text-align: right;
        }

        .bloco-03{
            width: 18%;
            background-image: url(${FundoLateral});
        }

@media screen and (max-width: 750px) {
    
    margin: 0px;
    padding: 0px;
    width: 100%;
    h1 { font-size: 3.0vw};
    h2 { font-size: 2.5vw};
    h3{ font-size: 2.0vw}

    .blocos{

        .bloco-01{

            .traco{
                width: 30vw;
                height: 0.4vh;
                background-color: red;
            }

            img {
                width: 45vw;
                margin-top: 2vh;
            }

            .mapa{

                    img {
                        width: 22vw;
                        margin-top: 3vh;
                    }
            }
        }    
    }
}
`