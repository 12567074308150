import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/css';

import { DivCarrosel } from './style';

//import ferias_coletivas from '../img/ferias_coletivas.png';
import colaboradores from '../img/colaboradores.png';
import impressoras from '../img/impressoras.png';
import etiquetas from '../img/etiquetas.png';
import ribbon from '../img/ribbons.png';
import asstecnica from '../img/asstecnica.png';
import promocoes from '../img/promocoes.png';

export const Carrosel = () => {

    return (

        <DivCarrosel>
            <Swiper
                slidesPerView={1}
                loop={true}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                modules={[Autoplay]}
                className="swiper"
            >
                {/*<SwiperSlide><img src={ferias_coletivas} alt='imagem ferias_coletivas' /></SwiperSlide>*/}
                <SwiperSlide><img src={colaboradores} alt='imagem colaboradores' /></SwiperSlide>
                <SwiperSlide><img src={etiquetas} alt='imagens etiquetas' /></SwiperSlide>
                <SwiperSlide><img src={ribbon} alt='imagens ribbons' /></SwiperSlide>
                <SwiperSlide><img src={impressoras} alt='imagens impressoras' /></SwiperSlide>
                <SwiperSlide><img src={asstecnica} alt='imagens assistencia tecnica' /></SwiperSlide>
                <SwiperSlide> <img src={promocoes} alt='imagens promoções' /></SwiperSlide>
            </Swiper>
        </DivCarrosel>
    )
}
