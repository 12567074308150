import React from "react";
import { DivPromocoes } from './style';

import Sale from '../img/img_Sale.png'
import Logo from '../img/img_logoEPC_medio.png'

export const Promocoes = () => {

    return (
        <DivPromocoes>
            <div className="Promo">
                <div className="Sale">
                    <img src={Sale} alt="Venda SALE" />
                </div>

                <div className="prom-02 ">  Nossas Promoções
                    <li>Impressoras</li>
                    <li>Ribbons</li>
                    <li>Etiquetas</li>
                </div>
            </div>

            <div className="fundo-rodape">
                <img src={Logo} alt="logo etiquetapontocom" />
            </div>

        </DivPromocoes>
    )
}