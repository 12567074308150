import styled from "styled-components";
import Fundo from '../img/img_FundoAmarelo.jpg';

export const DivImpressoras = styled.div`
    margin: auto;
    width: 85.9%;
    margin-top: 2vh;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 2vh;
    background-color: rgb(87, 82, 82);
    color: white;
    text-align: center;

    .linha-1 {
        padding: 5vh;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-weight: bold;
        font-size: 3.3vw;
}
    .linha-2 {
        font-size: 2.2vw;
}
    .logo_impressoras{
        margin-top: 4.5vw;
        padding: 1.5vw;
        display: flex;
        justify-content: space-around;
        text-align: center;
        align-items: center;

        img{
            width: 16vw;
            padding: 1.0vw;
    }
}
    .fundo-rodape{
        background-image: url(${Fundo});
        background-repeat: no-repeat;
        background-size: cover;
        padding: 5px;
        text-align: left;
            img{
                width: 20%;
            }
}

@media screen and (max-width: 750px) {
        
margin: 0px;
padding: 0px;
width: 100%;

    .linha-1 {
        padding: 15px;
        font-size: 5.0vw;
    }

    .linha-2 {
        font-size: 3.5vw;
    }

}
`;