import React from "react";
import { DivSobre } from './style'

import Predios from '../img/img_predios.png'
import LogoMedio from '../img/img_logoEPC_medio.png'

export const SobreEPC = () => {

    return (
        <DivSobre>

            <div className="predios">
                <img src={Predios} alt='vista de predios' />
            </div>
            <div className="descritivo">
                <h1>SOBRE A EMPRESA</h1>
                <img src={LogoMedio} alt='logo da etiquetapontocom' />
                <div className="texto">
                    Fundada em 2002 a EPC vem expandindo e crescendo constantemente, conquistando o seu espaço no mercado de rótulos e adesivos, de forma limpa, transparente e honesta.
                    Seu parque gráfico conta com 1.000m² e a conversão média de 200.000m² de adesivos por mês.
                    Temos uma equipe de colaboradores com experiencia e capacitação profissional para desenvolver soluções conforme a necessidade de cada empresa, sempre com qualidade e rapidez.
                </div>
            </div>

        </DivSobre>
    )
};