import styled from "styled-components";

export const DivEtiquetas = styled.div`
    margin: auto;
    width: 85.9%;
    margin-top: 2vh;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 3.2vw;
    background-color: #696969;

.linha1 {
    padding: 3px;
    color: white;
    display: flex;
    align-items: center;
    border: solid 2px #D3D3D3;
}
.imagens {
    padding: 2px;
    width: 30%;
    background-color: white;
    display: flex;
    justify-content: space-around;

    img{
        width: 100% ;
    }
}

.titulos {
    margin-left: 8vw;
    text-align: center;
    width: 43vw;
    color: white;

    .tecnica{
        font-size: 2.0vw;
        color: #87CEFA;
        a {
            text-decoration: none;
            color: #48D1CC;
        }
    }
}

    @media screen and (max-width: 750px) {
        margin:0;
        padding:0;
        width: 100%;
    }

`;