import styled from "styled-components";

export const DivSobre = styled.div`
    margin: auto;
    width: 85.9%;
    margin-top: 1.5vh;
    background-color: #DCDCDC;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #696969;

    .predios {
        margin-left : 1vw;
        img {
            width: 21vw;
        }    
    }
    .descritivo{
        width: 55%;

        img {
            width: 45%;
        }
    }
    h1 {
        font-weight: bold;
        font-size: 2.7vw;
    }
    .texto {
        text-align: justify;
        margin: auto;
        padding: 3vw;
        font-weight: normal;
        font-size: 1.3vw;
    }

@media screen and (max-width: 750px) {
    margin:0;
    padding:0;
    width: 100%;

        .predios{ 
            img{ display: none } 
            margin: 0;
        }

        .descritivo {
            
            margin: auto;
            width: 90%;

            img{ display: none }

                h1{
                    font-size: 5vw;
                }

                .texto{            
                    width: 95%;
                    font-size: 2.2vw;
                    padding: 0;
                    padding-bottom: 20px;
                }
        }           
}

`;