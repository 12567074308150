import React from "react";
import { DivImpressoras } from './style';

import Zebra from '../img/img_Imp_Zebra2.png';
import Datamax from '../img/img_Imp_Datamax2.png';
import Argox from '../img/img_Imp_Argox2.png';
import Logo from '../img/img_logoEPC_medio.png';
import Elgin from '../img/img_Imp_Elgin2.png';

export const Impressoras = () => {

    return (
        <DivImpressoras>

                <div className="linha-1">
                    Impressoras
                </div>
                <div className="linha-2">
                    Trabalhamos com a venda e assistência
                    técnica <br /> das seguintes marcas de impressoras:
                </div>
                <div className="logo_impressoras">

                    <div>
                        <img src={Elgin} alt="impressora elgin" />
                    </div>
                    <div>
                        <img src={Zebra} alt="impressora zebra" />
                    </div>
                    <div>
                        <img src={Datamax} alt="impressora datamax" />
                    </div>
                    <div>
                        <img src={Argox} alt="impressora argox" />
                    </div>
                </div>

                <div className="fundo-rodape">
                    <img src={Logo} alt="logo etiquetapontocom" />
                </div>

        </DivImpressoras>
    )
}