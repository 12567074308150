import React from "react";
import { DivEtiquetas } from './style';
import { Link } from "react-router-dom";

import Logo from '../img/img_logoEPC_medio.png';
import Corrosivo from '../img/img_Etiq_Corrosivo.png';
import Etiqueta from '../img/img_Etiq_Rolo_Red.png';
import Promocao from '../img/img_Etiq_Promocao.png';

export const Etiquetas = () => {

    return (

        <DivEtiquetas>
            <div className="linha1">
                <div className="imagens">
                    <img src={Logo} alt='Logo etiquetapontocom' />
                </div>
                <div className="titulos">
                    NOSSAS ETIQUETAS
                </div>
            </div>
            <div className="linha1">
                <div className="imagens">
                    <img src={Corrosivo} alt='imagem corrosivo' />
                </div>
                <div className="titulos">
                    RÓTULOS ADESIVOS
                </div>
            </div>
            <div className="linha1">
                <div className="imagens">
                    <img src={Etiqueta} alt='imagem rolo etiqueta' />
                </div>
                <div className="titulos">
                    ETIQUETAS ADESIVAS
                </div>
            </div>
            <div className="linha1">
                <div className="imagens">
                    <img src={Promocao} alt='etiqueta promoção' />
                </div>
                <div className="titulos">
                    ETIQUETAS TAGS
                </div>
            </div>
            <div className="linha1">
                <div className="imagens">
                    <img src={Logo} alt='Logo etiquetapontocom' />
                </div>
                <div  className="titulos">
                    TECNICAS UTILIZADAS
                   <div className="tecnica">
                       FLEXOGRAFIA - <Link to='/digital/videos'>IMPRESSÃO DIGITAL</Link>
                    </div>   
                </div>
            </div>

        </ DivEtiquetas>

    )
};